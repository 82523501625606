import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import * as TextInputStories from '@stories/text-input.stories.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Example = makeShortcode("Example");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
    
    <ul>
    <li><a className="ln-c-link" href="#how-to-use-text-input">How to use text input</a></li>
    <li><a className="ln-c-link" href="#types-of-text-input">Types of text input</a></li>
    </ul>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-text-input",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-text-input",
        "aria-label": "how to use text input permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use text input`}</h2>
    <p>{`Text inputs are used in forms so that users can input data. They help users to understand what data they need to supply to progress.`}</p>
    <p>{`When using a text input:`}</p>
    <ul>
      <li parentName="ul">{`Use a label to explain exactly what data is required`}</li>
      <li parentName="ul">{`If fields are optional, add '(optional)' to the relevant label`}</li>
      <li parentName="ul">{`Programmatically connect the label to the input field`}</li>
      <li parentName="ul">{`Use appropriate HTML attributes to help the user to input data in the correct format`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "types-of-text-input",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-text-input",
        "aria-label": "types of text input permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of text input`}</h2>
    <h3>{`Standard`}</h3>
    <p>{`Use a label to make it clear what data is needed. Make sure the label is programmatically connected to the input field so the purpose of the input field will be announced to screen reader users.`}</p>
    <Example stories={TextInputStories} component={'Standard'} stretch mdxType="Example" />
    <h3>{`With required indicator`}</h3>
    <p>{`Use required to indicate that a field is required`}</p>
    <Example stories={TextInputStories} component={'WithRequiredIndicator'} stretch mdxType="Example" />
    <h3>{`With supporting text`}</h3>
    <p>{`If there’s more to say than can fit in the label, use supporting text.`}</p>
    <p>{`If there are specific requirements on format, make that clear before the user tries to submit in the wrong format and sees an error message.`}</p>
    <Example stories={TextInputStories} component={'WithSupportingText'} stretch mdxType="Example" />
    <h3>{`With placeholder text`}</h3>
    <p>{`Placeholder text can be used to provide an example of the type of data that is expected.`}</p>
    <p>{`Placeholder text disappears when the field receives focus. Because of this, it shouldn’t be essential for a user to progress, or the only piece of information a user gets about a field.`}</p>
    <Example stories={TextInputStories} component={'WithPlaceholder'} stretch mdxType="Example" />
    <h3>{`With error message`}</h3>
    <p>{`Use an error message if the user can’t complete their action without making a change.`}</p>
    <p>{`Use ARIA to alert screen reader users to the error message.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "/copy/writing-in-experiences/error-messages/"
      }}>{`read guidance about how to write error messages here`}</a>{`.`}</p>
    <Example stories={TextInputStories} component={'WithErrorMessage'} stretch mdxType="Example" />
    <h3>{`With icon`}</h3>
    <p>{`Icons can be used within the field to support the label.`}</p>
    <p>{`Icons shouldn’t be needed to understand the input field and shouldn’t be able to receive focus.`}</p>
    <Example stories={TextInputStories} component={'WithIcon'} stretch mdxType="Example" />
    <Example stories={TextInputStories} component={'WithAction'} stretch mdxType="Example" />
    <h3>{`With buttons`}</h3>
    <p>{`When an action is directly tied to a field, then a button can be shown inside or grouped with the input.`}</p>
    <p>{`The button must have a text or text alternative accessible name.`}</p>
    <Example stories={TextInputStories} component={'WithButton'} stretch mdxType="Example" />
    <Example stories={TextInputStories} component={'WithMultipleButtons'} stretch mdxType="Example" />
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using text inputs or give you a
  helping hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      